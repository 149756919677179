<template>
  <detailed-view-container questionnaireTitle="General Questions">
    <template v-slot:questionnnairePanel>
      <GeneralQuestionsPanels
        :questionnaires="questionnaires"
        :questions="questionnaireQuestions"
      />
    </template>
  </detailed-view-container>
</template>

<script>
const components = {
  GeneralQuestionsPanels: () =>
    import(
      "@/components/views/dashboard/members/id/partial/questionnaires/components/GeneralQuestionsPanels.vue"
    ),
  DetailedViewContainer: () =>
    import(
      "@/components/views/dashboard/members/id/partial/questionnaires/DetailedViewContainer.vue"
    ),
};

export default {
  name: "GeneralQuestions",

  data() {
    return {
      questionnaires: [
        {
          date: "May 28, 2020",
          score: 20,
        },
        {
          date: "September 18, 2020",
          score: 10,
        },
        {
          date: "May 04, 2020",
          score: 12,
        },
        {
          date: "January 12, 2020",
          score: 8,
        },
        {
          date: "September 22, 2020",
          score: 10,
        },
        {
          date: "December 18, 2020",
          score: 6,
        },
        {
          date: "February 14, 2020",
          score: 2,
        },
      ],
      questionnaireQuestions: [
        {
          question:
            "¿Se encuentra bien con el tratamiento pautado en la actualidad?",
          answers: [
            {
              text: "Si",
              value: true,
            },
            {
              text: "No",
              value: false,
            },
          ],
        },
        {
          question:
            "¿Ha tenido síntomas que le recuerden a brotes de su enfermedad?",
          answers: [
            {
              text: "Si",
              value: true,
            },
            {
              text: "No",
              value: false,
            },
          ],
          subQuestion: {
            question:
              "Si su respuesta es afirmativa indique cuántos brotes ha tenido en el último mes :",
            answer: "2",
          },
        },
        {
          question:
            "¿Ha tenido algún síntoma que atribuya a problemas de tolerancia del tratamiento actual?",
          answers: [
            {
              text: "Si",
              value: true,
            },
            {
              text: "No",
              value: false,
            },
          ],
          subQuestion: {
            question:
              "Si su respuesta es afirmativa indique que síntoma y a qué tratamiento lo atribuye :",
            answer: "Response",
          },
        },
        {
          question: "¿Ha presentado fiebre o infecciones recientemente?",
          answers: [
            {
              text: "Si",
              value: false,
            },
            {
              text: "No",
              value: true,
            },
          ],
        },
        {
          question:
            "¿Ha realizado actividad física equivalente a caminar 30 minutos, al menos 3 veces en la última semana?",
          answers: [
            {
              text: "Si",
              value: false,
            },
            {
              text: "No",
              value: true,
            },
          ],
        },
         {
          question:
            "¿Se le olvida tomar la medicación?",
          answers: [
            {
              text: "Nunca",
              value: false,
            },
            {
              text: "A veces",
              value: false,
            },
            {
              text: "Frecuentemente",
              value: true,
            },
            {
              text: "Siempre",
              value: false,
            },
          ],
        },
      ],
    };
  },

  components,
};
</script>